import React from 'react';

export const Icons = {
    close: (
        <svg viewBox="0 0 15.8 15.8" role="presentation" focusable="false" width="100%" height="100%">
            <path d="M8.7 7.9l7.1 7.1-.8.8-7.1-7.1-7.1 7.1L0 15l7.1-7.1L0 .8.8 0l7.1 7.1L15 0l.8.8z"></path>
        </svg>
    ),
    failure: (
        <svg viewBox="0 0 9 9" className="error-icon" aria-hidden="true" role="presentation" focusable="false" width="15" height="15">
            <circle cx="4.5" cy="4.5" r="4.5" aria-hidden="true" role="presentation" style={{ fill: "red" }}></circle>
            <polygon points="7,2.8 6.2,2 4.5,3.7 2.8,2 2,2.8 3.7,4.5 2,6.2 2.8,7 4.5,5.3 6.2,7 7,6.2 5.3,4.5" aria-hidden="true" role="presentation" style={{ fill: "white" }} ></polygon>
        </svg >
    ),
    hyperlink: (
        <svg viewBox="0 0 16 16" className="fxs-portal-svg hyperlink-icon" role="presentation" focusable="true">
            <path d="M4.992 9.819l8.018-7.991v4.005H14V0H8.167v1h4.038L4.232 8.987l.76.833z"></path>
            <path d="M12.993 13.03H1.01V1.028h4.823V0H0v14h14V8.167h-1.007v4.863z"></path>
        </svg>
    ),
    loading: (
        <svg height="40px" viewBox="0 0 31.3 31.3" className="msportalfx-svg-c19 msportalfx-svg-rotate360" role="presentation" focusable="false" aria-hidden="true">
            <path opacity="0.3" d="M22 23.1l3.4 4.7c2.1-1.7 3.8-4 4.8-6.6l-5.6-1.8c-.5 1.4-1.4 2.7-2.6 3.7z" aria-hidden="true" role="presentation"></path>
            <path d="M20.8 7.3l3.4-4.7C22 1 19.3.1 16.4 0v5.9c1.6.1 3.1.6 4.4 1.4z" aria-hidden="true" role="presentation"></path>
            <path opacity="0.9" d="M14.9 5.9V0C12 .1 9.4 1 7.1 2.5l3.4 4.7c1.3-.7 2.8-1.2 4.4-1.3z" aria-hidden="true" role="presentation"></path>
            <path opacity="0.1" d="M24.7 11.9l5.6-1.8c-1-2.6-2.7-4.9-4.8-6.6L22 8.2c1.2 1 2.1 2.2 2.7 3.7z" aria-hidden="true" role="presentation"></path>
            <path opacity="0.8" d="M9.3 8.2L5.8 3.4C3.7 5.2 2 7.4 1 10.1l5.6 1.8c.6-1.5 1.5-2.7 2.7-3.7z" aria-hidden="true" role="presentation"></path>
            <path opacity="0.4" d="M16.4 25.4v5.9c2.9-.1 5.5-1 7.8-2.5L20.8 24c-1.3.8-2.8 1.3-4.4 1.4z" aria-hidden="true" role="presentation"></path>
            <path opacity="0.6" d="M6.6 19.4L1 21.2c1 2.6 2.7 4.9 4.8 6.6l3.4-4.7c-1.1-1-2-2.3-2.6-3.7z" aria-hidden="true" role="presentation"></path>
            <path opacity="0.7" d="M5.9 15.6c0-.8.1-1.5.3-2.3L.6 11.5c-.4 1.3-.6 2.7-.6 4.1s.2 2.8.6 4.1l5.6-1.8c-.2-.7-.3-1.5-.3-2.3z" aria-hidden="true" role="presentation"></path>
            <path opacity="0.5" d="M10.6 24l-3.4 4.7c2.3 1.5 4.9 2.4 7.8 2.5v-5.9c-1.7 0-3.2-.5-4.4-1.3z" aria-hidden="true" role="presentation"></path>
            <path opacity="0.2" d="M25.5 15.6c0 .8-.1 1.5-.3 2.3l5.6 1.8c.4-1.3.6-2.7.6-4.1s-.2-2.8-.6-4.1l-5.6 1.8c.2.8.3 1.5.3 2.3z" aria-hidden="true" role="presentation"></path>
        </svg>
    ),
    search: (
        <svg viewBox="0 0 50 50" role="presentation" focusable="false" width="100%" height="100%">
            <path d="M21.8.6c1.5.4 2.8 1 4.1 1.7 1.2.8 2.4 1.7 3.4 2.7 1 1.1 1.9 2.2 2.7 3.5.7 1.3 1.3 2.6 1.7 4.1.4 1.5.6 3 .6 4.6 0 2-.3 4-1 5.8s-1.7 3.6-3 5.1l19.1 19.2c.3.3.5.7.5 1.1 0 .4-.2.8-.5 1.1-.3.3-.7.5-1.1.5-.4 0-.8-.2-1.1-.5l-19-19.1c-1.6 1.3-3.3 2.3-5.1 3-1.9.7-3.8 1-5.8 1-1.6 0-3.1-.2-4.6-.6-1.5-.4-2.8-1-4.1-1.7-1.3-.7-2.4-1.6-3.5-2.7-1-1.1-1.9-2.2-2.7-3.5-.7-1.3-1.3-2.6-1.7-4.1-.4-1.5-.6-3-.6-4.6 0-1.6.2-3.1.6-4.6.4-1.5 1-2.8 1.7-4.1C3.1 7.3 4 6.1 5 5c1-1 2.2-1.9 3.5-2.7C9.8 1.6 11.1 1 12.6.6c1.5-.4 3-.6 4.6-.6s3.1.2 4.6.6zm.9 29.5c1.7-.7 3.2-1.7 4.5-3s2.3-2.8 3-4.5c.7-1.7 1.1-3.5 1.1-5.5 0-1.9-.4-3.8-1.1-5.5-.7-1.7-1.7-3.2-3-4.5s-2.8-2.3-4.5-3C21 3.4 19.2 3 17.2 3s-3.8.4-5.5 1.1C10 5 8.5 6 7.3 7.3S5 10 4.2 11.7c-.7 1.7-1.1 3.5-1.1 5.5 0 1.9.4 3.8 1.1 5.5.7 1.7 1.7 3.2 3 4.5s2.8 2.3 4.5 3c1.7.7 3.5 1.1 5.5 1.1s3.7-.4 5.5-1.2z"></path>
        </svg>
    ),
    info: (
        <svg viewBox="0 0 16 16" className="fxs-portal-svg" role="presentation" focusable="false" width="100%" height="100%">
            <g>
                <circle cx="8" cy="8" r="8" className="msportalfx-svg-c35"></circle>
                <circle cx="8" cy="4.116" r="1.449" className="msportalfx-svg-c01"></circle>
                <path d="M9.1 13.987v-7H6.911l-.011 7h2.2z" className="msportalfx-svg-c01"></path>
            </g>
        </svg>
    ),
    skipped: (
        <svg viewBox="0 0 16 16" className="skipped-icon" role="presentation" focusable="false" width="16" height="16">
            <circle cx="8" cy="8" r="7" fill="#fff"></circle>
            <path fillRule="evenodd" clipRule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-1.5 0a6.5 6.5 0 1 1-13 0 6.5 6.5 0 0 1 13 0zM6.41 5.124a.9.9 0 1 0-1.274 1.272l4.385 4.385a.9.9 0 1 0 1.272-1.273L6.41 5.124z"></path>
        </svg>
    ),
    success: (
        <svg viewBox="2.5 2.5 12 12" className="success-icon" role="presentation" focusable="false" width="15" height="15">
            <path d="M8.5 14C5.364 14 3 11.636 3 8.5S5.364 3 8.5 3 14 5.364 14 8.5 11.636 14 8.5 14z" style={{ fill: "#7fba00" }}></path>
            <path d="M8.5 3.5c2.897 0 5 2.103 5 5s-2.103 5-5 5-5-2.103-5-5 2.103-5 5-5m0-1c-3.429 0-6 2.571-6 6s2.571 6 6 6 6-2.571 6-6-2.571-6-6-6z" style={{ fill: "#7fba00" }}></path>
            <path d="M5.071 8.5l.858-.857 1.714 1.714 3.428-3.428.858.857-4.286 4.285z" style={{ fill: "#fff" }}></path>
        </svg>
    ),
    pending: (
        <svg width="16" height="16" viewBox="0 0 16 16">
            <path fill="#015CDA" d="M8 15.4C3.92 15.4.6 12.081.6 8 .6 3.92 3.92.6 8 .6c4.081 0 7.4 3.32 7.4 7.4 0 4.081-3.319 7.4-7.4 7.4z" />
            <path fill="#FFF" d="M8 1.013a6.987 6.987 0 110 13.974A6.987 6.987 0 018 1.013M8 0C3.589 0 0 3.589 0 8s3.589 8 8 8 8-3.589 8-8-3.589-8-8-8z" />
            <path fill="#FFF" d="M7.843 3.794h-.047a4.4 4.4 0 00-2.617.888L3.697 3.2v3.902h3.901L6.126 5.629a2.762 2.762 0 011.717-.577c1.464 0 2.678 1.03 2.933 2.376h1.287a4.26 4.26 0 00-4.22-3.634zm4.414 5.094H8.402l.019.01h-.019l1.426 1.426c-.484.339-1.061.52-1.717.52-1.321 0-2.445-.893-2.829-2.096h-1.35a4.268 4.268 0 004.178 3.354h.046c.963 0 1.858-.332 2.586-.862l1.56 1.56V8.888h-.045z" />
        </svg>
    ),
    warning: (
        <svg width="16" height="16" viewBox="0 0 16 16">
            <path fill="#FF8C00" d="M14.818 13.18L9.105 1.674a1.242 1.242 0 00-2.209 0L1.183 13.001c-.465.899 0 1.979.93 1.979h11.775c.93.179 1.395-.899.93-1.8z" />
            <path fill="#FFF" d="M8 1c.436 0 .872.225 1.105.674l5.712 11.506c.431.834.064 1.82-.731 1.82-.064 0-.131-.006-.2-.02H2.113c-.93 0-1.395-1.08-.93-1.979L6.896 1.674A1.23 1.23 0 018 1m0-1c-.842 0-1.606.466-1.992 1.216L.29 12.551c-.403.779-.386 1.713.05 2.429a2.036 2.036 0 001.773 1.001h11.694c.094.013.188.02.28.02.663 0 1.243-.33 1.594-.904.418-.686.428-1.596.026-2.374L10.001 1.23A2.244 2.244 0 008 0z" />
            <circle cx="8" cy="12.375" r="1.268" fill="#FFF" />
            <path fill="#FFF" d="M6.775 4.5l.438 5.25h1.575l.437-5.25h-2.45z" />
        </svg>
    ),
    indicators: (
        <svg style={{ display: "none" }}>
            <defs>
                <polygon points="0,8 7,0 14,8 " id="blueUpIndicator" style={{ fill: "#1f7fff" }} />
                <polygon points="0,8 7,0 14,8 " id="greenUpIndicator" style={{ fill: "#009f00" }} />
                <polygon points="0,8 7,0 14,8 " id="redUpIndicator" style={{ fill: "#ff3f3f" }} />
                <polygon points="0,0 7,8 14,0 " id="blueDownIndicator" style={{ fill: "#3fbfff" }} />
                <polygon points="0,0 7,8 14,0 " id="greenDownIndicator" style={{ fill: "#009f00" }} />
                <polygon points="0,0 7,8 14,0 " id="redDownIndicator" style={{ fill: "#ff3f3f" }} />
            </defs>
        </svg>
    )
}

export enum IndicatorMode {
    Default = 1,
    Inverse = 2,
    Neutral = 3,
}

export function getIndicatorIcon(previous: number, current: number, mode?: IndicatorMode): React.ReactNode {
    let id = "";
    if (previous < current) {
        switch (mode) {
            case IndicatorMode.Neutral:
                id = "blueUpIndicator";
                break;
            case IndicatorMode.Inverse:
                id = "redUpIndicator";
                break;
            default:
                id = "greenUpIndicator";
                break;
        }
    }

    if (previous > current) {
        switch (mode) {
            case IndicatorMode.Neutral:
                id = "blueDownIndicator";
                break;
            case IndicatorMode.Inverse:
                id = "greenDownIndicator";
                break;
            default:
                id = "redDownIndicator";
                break;
        }
    }

    return id
        ? <svg style={{ height: "8px", width: "14px", padding: "1px" }}><use href={`#${id}`}></use></svg>
        : "";
}