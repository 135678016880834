import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Link } from 'react-router-dom';
import { cloud } from '../Common/Environment';
import { Icons } from '../Common/Icons';
import { cloudKusto } from '../Common/Kusto';
import './TopBar.css';

interface Props {
  title: string;
}

const TopBar = (props: Props) => {
  const { title } = props;
  const { instance, accounts } = useMsal();
  const username = accounts[0]?.name;
  const [showSignOut, setShowSignOut] = React.useState(false);
  const [showSwitcher, setShowSwitcher] = React.useState(false);
  let signOutClassName = "fxs-topbar-signout";
  if (showSignOut) {
    signOutClassName += " fxs-topbar-signout-visible"
  }

  return (
    <>
      <div className="fxs-topbar">
        <div className="fxs-topbar-left">
          <h1 className="fxs-topbar-home"><Link to="/">Extension Analyzer</Link></h1>
          <span className="fxs-topbar-title">{title}</span>
        </div>
        <div className="fxs-topbar-right">
          <span className="fxs-topbar-cloud" onClick={() => setShowSwitcher(true)}>{`Environment: ${cloudKusto.displayName}`}</span>
          <a className="fxs-topbar-reportbuglink" href="https://aka.ms/portalfx/extensionanalyzerbug" target="_blank" rel="noopener noreferrer">Report a bug</a>
          <span className="fxs-topbar-username" onClick={() => setShowSignOut(!showSignOut)}>{username || "Logging in"}</span>
          <button className={signOutClassName} onClick={() => instance.logout()}>
            <span>Sign out</span>
            <span>✕</span>
          </button>
        </div>
      </div>
      {showSwitcher && (
        <div className="fxs-controls-cloudswitcher">
          <div className="fxs-controls-cloudswitcher-content">
            <button className="fxs-controls-cloudswitcher-content-closebutton" onClick={() => setShowSwitcher(false)}>{Icons.close}</button>
            <div className="fxs-controls-cloudswitcher-content-page">
              <div>{resources.intro}</div>
              <div className="fxs-controls-cloudswitcher-currentcloud">
                <span >{resources.cloudLabel}</span><span>{cloudKusto.displayName}</span>
              </div>
              <ul>
                {cloud !== "prod" && <li><button className="fxs-button-link" onClick={goPublic}>{resources.publicLink}</button></li>}
                <li>
                  <button className="fxs-button-link" onClick={openSwitcher}>{resources.nationalLink}</button>
                  <div>{resources.nationalIntro}</div>
                </li>
              </ul>
            </div>
          </div>
        </div>)}
    </>
  );
};

export function openSwitcher() {
  let listener: () => void;
  const timeout = setTimeout(() => {
    window.location.href = "https://commits.blob.core.windows.net/kustodsts/ExtensionAnalyzer.KustoDsts.application";
    window.removeEventListener("blur", listener);
  }, 200);
  listener = () => {
    window.clearTimeout(timeout);
    window.removeEventListener("blur", listener);
  };
  window.addEventListener("blur", listener);
  window.location.href = "extensionanalyzerdsts:" + window.location.href.substr(window.location.origin.length);
};

function goPublic() {
  sessionStorage.removeItem("analyzer.cloud");
  sessionStorage.removeItem(`analyzer.${cloud}-dsts`);
  window.location.reload();
};

const resources = {
  intro: "Extension Analyzer queries portal and ARM Kusto clusters in the selected cloud on your behalf to retrieve telemetry data.",
  cloudLabel: "Current cloud: ",
  publicLink: "Return to Public Azure",
  nationalLink: "Switch to a national cloud (Windows only)",
  nationalIntro: "Use the ClickOnce program to generate a dSTS token and relaunch Extension Analyzer."
}

export default React.memo(TopBar);
