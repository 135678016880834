import React from 'react';
import { Icons } from '../Common/Icons';
import { ajaxWithAuth } from '../Common/Ajax';
import Banner, { BannerValue } from '../Components/Banner';
import { cloudKusto } from '../Common/Kusto';
import KustoLinks from '../Components/KustoLinks';
import Grid, { Column, Align } from '../Components/Grid';
import { useParams } from 'react-router-dom';

interface Params {
  productName: string;
}

const ProductCreateFlows = () => {
  const { productName } = useParams<Params>();
  const [banner, setBanner] = React.useState<BannerValue>();
  const [queryText, setQueryText] = React.useState("");
  const [response, setResponse] = React.useState<ProductCreatesResponse>();
  React.useEffect(() => {
    setBanner({ text: resources.loadingLabel, svg: Icons.loading });

    ajaxWithAuth<ProductCreatesResponse>(`/api/products/${productName}/creates`).then(res => {
      setResponse(res)
      setBanner(undefined);
    }).catch(error => {
      setBanner({ svg: Icons.failure, error });
    });
  }, [productName]);
  const dataFreshness = response && resources.dataFreshnessFormat.replace("{0}", response.endTime);
  return (
    <>
      <Banner value={banner} />
      <div className="fxs-tab-section fxs-wrapitems">
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-create-environment">{resources.environmentLabel}</div>
          <select className="fxs-tab-section-field-value" aria-labelledby="product-create-environment" value="prod" disabled>
            <option value="prod">{cloudKusto.displayName}</option>
          </select>
        </div>
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-create-search">{resources.queryTextLabel}</div>
          <input className="product-create-search" aria-labelledby="product-create-search" type="search" value={queryText} onChange={e => setQueryText(e.target.value)} />
        </div>
      </div>
      <section className="fxs-tab-section">
        <h2 style={{ margin: 0 }} >{resources.createsSectionHeader}</h2>
        <div style={{ margin: "8px 0" }}>{dataFreshness}</div>
        <KustoLinks server={response?.kustoServerName} database={response?.kustoDatabaseName} query={response?.kustoQueryText} />
        <Grid
          columns={columns}
          rows={response?.creates}
          altRows={true}
          queryText={queryText}
          pageSize={20}
          noRowsMessage={response && resources.noBladeLoadsData} />
      </section>
    </>
  );
};

const resources = {
  loadingLabel: "Downloading resource create information",
  dataFreshnessFormat: `Usage for week ending {0}`,
  createsSectionHeader: "Create flows",
  noBladeLoadsData: "No creates were attempted during the specified period",
  gridHeaders: {
    extensionName: "Extension",
    createBladeName: "Create blade name",
    totalAttempts: "Total create attempts",
    successPercent: "Success percent",
    uniqueUserCount: "Total unique users",
  },
  environmentLabel: "Environment",
  queryTextLabel: "Filter",
};

const columns: Column<ProductCreatesResponseItem>[] = [
  {
    headerText: resources.gridHeaders.extensionName,
    key: row => (<a href={`/extensions/${row.extensionName}#create`}>{row.extensionName}</a>),
    sortKey: row => row.extensionName
  },
  {
    headerText: resources.gridHeaders.createBladeName,
    key: row => row.createBladeName,
  },
  {
    headerText: resources.gridHeaders.totalAttempts,
    key: row => row.totalAttempts,
    align: Align.Right,
  },
  {
    headerText: resources.gridHeaders.successPercent,
    key: row => Math.round(row.successCount * 10000 / row.totalAttempts) / 100,
    align: Align.Right,
  },
  {
    headerText: resources.gridHeaders.uniqueUserCount,
    key: row => row.uniqueUserCount,
    align: Align.Right,
  },
];


export default React.memo(ProductCreateFlows);
