import { MessageBar, MessageBarType } from '@fluentui/react';
import React, { memo, useEffect, useState } from 'react';

export const ErrorMessageBar = memo((props: { error?: any; }) => {
    const { error } = props;
    const [message, setMessage] = useState<string>();
    useEffect(() => {
        let disposed = false;
        getErrorMessage(error).then(message => {
            !disposed && setMessage(message);
        });

        return () => { disposed = true; }
    }, [error, setMessage]);

    if (!error) {
        return null;
    }

    return (
        <MessageBar
            messageBarType={MessageBarType.error}>
            {message}
        </MessageBar>
    );
});

const networkErrorSet = new Set(["Failed to fetch", "Load failed"]);

export async function getErrorMessage(error: any): Promise<string> {
    if (error) {
        console.error(error);
    }

    if (error instanceof TypeError && networkErrorSet.has(error.message)) {
        return "Network error. Please use AzVPN when connecting from a non-Microsoft IP.";
    } else if (error instanceof Error) {
        return `${error.name}: ${error.message}`;
    } else if (error instanceof Response) {
        return error.clone().text().catch(() => "").then(response => {
            return `${error.status} ${error.statusText}: ${response}`;
        });
    } else {
        const responseError = error?.responseJSON.error;
        if (responseError && (responseError.code || responseError.message)) {
            return `${responseError.code}: ${responseError.message}`;
        }

        return JSON.stringify(error);
    }
}
