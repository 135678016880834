import React from 'react';
import { Icons, getIndicatorIcon, IndicatorMode } from '../Common/Icons';
import { cloud } from '../Common/Environment';
import { ajaxWithAuth } from '../Common/Ajax';
import Banner, { BannerValue } from '../Components/Banner';
import { cloudKusto } from '../Common/Kusto';
import KustoLinks from '../Components/KustoLinks';
import Grid, { Column, Align } from '../Components/Grid';
import { useParams } from 'react-router-dom';

interface Params {
  productName: string;
}

const ProductBlades = () => {
  const { productName } = useParams<Params>();
  const [banner, setBanner] = React.useState<BannerValue>();
  const [queryText, setQueryText] = React.useState("");
  const [performanceThreshold, setPerformanceThreshold] = React.useState<Threshold>("Default");
  const [showHighUsageOnly, setShowHighUsageOnly] = React.useState(cloud === "prod");
  const [showAboveThresholdOnly, setShowAboveThresholdOnly] = React.useState(false);
  const [response, setResponse] = React.useState<ProductBladesResponse>();

  React.useEffect(() => {
    setBanner({ text: resources.loadingLabel, svg: Icons.loading });
    ajaxWithAuth<ProductBladesResponse>(`/api/products/${productName}/blades?env=${cloud}`, { useDsts: true }).then(res => {
      setResponse(res)
      setBanner(undefined);
    }).catch(error => {
      setBanner({ svg: Icons.failure, error });
    });
  }, [productName]);
  const thresholds: Threshold[] = ["Default", "3 secs", "2 secs"];
  const dataFreshness = response && resources.dataFreshnessFormat.replace("{0}", response.endTime);
  const columns = getColumns(performanceThreshold);
  const rows = response?.blades.filter(item => {
    return (!showHighUsageOnly || item.bladeLoadCount >= 5000) && (!showAboveThresholdOnly || item.duration95 >= getThreshold(item, performanceThreshold));
  });
  
  return (
    <>
      <Banner value={banner} />
      <div className="fxs-tab-section fxs-wrapitems">
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-blades-environment">{resources.environmentLabel}</div>
          <select className="fxs-tab-section-field-value" aria-labelledby="product-blades-environment" disabled>
            <option value="prod">{cloudKusto.displayName}</option>
          </select>
        </div>
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-blades-search">{resources.queryTextLabel}</div>
          <input className="product-blades-search" aria-labelledby="product-blades-search" type="search" value={queryText} onChange={e => setQueryText(e.target.value)} />
        </div>
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-blades-performancethreshold">{resources.performanceThreshold}</div>
          <select value={performanceThreshold} onChange={e => setPerformanceThreshold(e.target.value as Threshold)}>
            {thresholds.map(item => (<option value={item} key={item}>{item}</option>))}
          </select>
        </div>
        <div className="fxs-tab-section-field">
          <input type="checkbox" aria-labelledby="product-blades-highusageonly" checked={showHighUsageOnly} onChange={e => setShowHighUsageOnly(e.target.checked)} />
          <div id="product-blades-highusageonly">{resources.showHighUsageOnly}</div>
        </div>
        <div className="fxs-tab-section-field">
          <input type="checkbox" aria-labelledby="product-blades-abovethresholdonly" checked={showAboveThresholdOnly} onChange={e => setShowAboveThresholdOnly(e.target.checked)} />
          <div id="product-blades-abovethresholdonly">{resources.showAboveThresholdOnlyOnly}</div>
        </div>
      </div>
      <section className="fxs-tab-section">
        <h2 style={{ margin: 0 }} >{resources.bladesSectionHeader}</h2>
        <div style={{ margin: "8px 0" }}>{dataFreshness}</div>
        <KustoLinks server={response?.kustoServerName} database={response?.kustoDatabaseName} query={response?.kustoQueryText} />
        <Grid
          columns={columns}
          rows={rows}
          altRows={true}
          queryText={queryText}
          pageSize={20}
          noRowsMessage={response && resources.noBladeLoadsData} />
      </section>
    </>
  );
};

function getThreshold(row: ProductBladesResponseItem, threshold: Threshold) {
  switch (threshold) {
    case "2 secs":
      return 2000;
    case "3 secs":
      return 3000;
    case "Default":
    default:
      return row.bladeLoadCount > 200000 ? 3000 : 4000;
  }
}

function getColumns(threshold: Threshold) {
  const columns: Column<ProductBladesResponseItem>[] = [
    {
      headerText: resources.gridHeaders.extensionName,
      key: row => (<a href={`/extensions/${row.extensionName}#blades`}>{row.extensionName}</a>),
      sortKey: row => row.extensionName
    },
    {
      headerText: resources.gridHeaders.bladeName,
      key: row => (<a href={`/extensions/${row.extensionName}/blades/${row.bladeName}`}>{row.bladeName}</a>),
      sortKey: row => row.bladeName
    },
    {
      headerText: resources.gridHeaders.bladeLoadCount,
      key: row => (<>{getIndicatorIcon(row.bladeLoadCountPrevious, row.bladeLoadCount, IndicatorMode.Neutral)}{row.bladeLoadCount}</>),
      sortKey: row => row.bladeLoadCount,
      align: Align.Right,
    },
    {
      headerText: resources.gridHeaders.uniqueUserCount,
      key: row => (<>{getIndicatorIcon(row.uniqueUserCountPrevious, row.uniqueUserCount, IndicatorMode.Neutral)}{row.uniqueUserCount}</>),
      sortKey: row => row.uniqueUserCount,
      align: Align.Right,
    },
    {
      headerText: resources.gridHeaders.duration50,
      key: row => (<>{getIndicatorIcon(row.duration50Previous, row.duration50, IndicatorMode.Inverse)}{row.duration50}</>),
      sortKey: row => row.duration50,
      getCss: row => row.duration50 < 1000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
      align: Align.Right,
    },
    {
      headerText: resources.gridHeaders.duration80,
      key: row => (<>{getIndicatorIcon(row.duration80Previous, row.duration80, IndicatorMode.Inverse)}{row.duration80}</>),
      sortKey: row => row.duration80,
      getCss: row => row.duration80 < 2000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
      align: Align.Right,
    },
    {
      headerText: resources.gridHeaders.duration95,
      key: row => (<>{getIndicatorIcon(row.duration95Previous, row.duration95, IndicatorMode.Inverse)}{row.duration95}</>),
      sortKey: row => row.duration95,
      getCss: row => row.duration95 < getThreshold(row, threshold) ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
      align: Align.Right,
    },
    {
      headerText: resources.gridHeaders.duration99,
      key: row => (<>{getIndicatorIcon(row.duration99Previous, row.duration99, IndicatorMode.Inverse)}{row.duration99}</>),
      sortKey: row => row.duration99,
      getCss: row => row.duration99 < 8000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
      align: Align.Right,
    },
  ];
  return columns;
}

const resources = {
  loadingLabel: "Downloading blade load information",
  bladesSectionHeader: "Blade usage",
  showHighUsageOnly: "Show high usage blades only (+5k loads per week)",
  showAboveThresholdOnlyOnly: "Show above threshold only",
  performanceThreshold: "Performance threshold",
  noBladeLoadsData: "No blades were loaded during the specified period",
  gridHeaders: {
    extensionName: "Extension",
    bladeName: "Blade",
    bladeLoadCount: "Load count",
    duration50: "Load P50",
    duration80: "Load P80",
    duration95: "Load P95",
    duration99: "Load P99",
    uniqueUserCount: "Total unique users",
  },
  dataFreshnessFormat: `Usage for week ending {0}`,
  environmentLabel: "Environment",
  queryTextLabel: "Filter"
};

type Threshold = "Default" | "3 secs" | "2 secs";

export default React.memo(ProductBlades);
