import { useState, useEffect, memo } from "react";
import { getErrorMessage } from "../Common/ErrorMessageBar";

export interface BannerValue {
    svg: React.ReactNode;
    text?: string;
    error?: any;
}

interface Props {
    value?: BannerValue;
}

const Banner = (props: Props) => {
    const { value } = props;
    const error = value?.error;
    const [finalError, setFinalError] = useState("");
    useEffect(() => {
        let disposed = false;
        setFinalError("");
        getErrorMessage(error).then(message => {
            !disposed && setFinalError(message);
        });
        return () => { disposed = true };
    }, [error]);


    return (
        <>
            {value && (
                <div className="fxs-banner">
                    <span className="fxs-banner-icon">{value.svg}</span>
                    <span className="fxs-banner-bannertext">
                        {value.error ? finalError : value.text}
                    </span>
                </div>
            )}
        </>
    );
};

export default memo(Banner);
