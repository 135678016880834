import React from 'react';
import { useRouteMatch, RouteComponentProps } from 'react-router-dom';
import { useHashInfo } from "../Hooks/UseHashInfo";

export interface TabOptions {
  id: string;
  title: string;
  content: React.ReactNode;
  hidden?: boolean;
}

interface Props extends RouteComponentProps {
  tabs: TabOptions[];
}

const Tabs = (props: Props) => {
  const tabs = props.tabs.filter(item => item.hidden !== true);
  const hashInfo = useHashInfo();
  const currentTab = hashInfo.path || tabs[0]?.id;
  const match = useRouteMatch();
  return (
    <>
      <div className="fxs-header">
        {tabs.filter(item => item.hidden !== true).map(item => {
          let className = "fxs-header-tab";
          if (item.id === currentTab) {
            className += " fxs-header-tab-selected";
          }

          return (
            <div className={className} key={item.id}>
              <a href={`${match.url}${window.location.search}#${item.id}`}>{item.title}</a>
            </div>
          );
        })}
      </div>
      <div className="fxs-content">
        {tabs.filter(item => item.id === currentTab)[0]?.content}
      </div>
    </>
  );
};

export default React.memo(Tabs);
