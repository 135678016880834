import React, { CSSProperties } from "react";
import Chart, { ChartConfiguration } from "chart.js";

interface Props {
  config?: ChartConfiguration;
  className?: string;
  style?: CSSProperties;
}

const ChartJs = (props: Props) => {
  const { config, className, style } = props;
  const chartRef = React.useRef<HTMLCanvasElement>(null);
  const chartElem = chartRef.current;
  React.useEffect(() => {
    const chart = chartElem && config && new Chart(chartElem, config);
    return () => {
      chart?.destroy();
    };
  }, [config, chartElem]);
  return (
    <>
      <canvas ref={chartRef} className={className} style={style}></canvas>
    </>
  );
};

export const color = Chart.helpers.color;

export const chartColors = {
  red: "rgb(255, 99, 132)",
  orange: "rgb(255, 159, 64)",
  yellow: "rgb(255, 205, 86)",
  green: "rgb(72, 225, 119)",
  blue: "rgb(54, 162, 235)",
  purple: "rgb(153, 102, 255)",
  grey: "rgb(201, 203, 207)",
  tonedBlue: "rgb(129, 197, 214)",
};

export interface TrendlineOptions {
  yAxisId?: string;
}

export function addTrendline(chartInstance: Chart, options: TrendlineOptions) {
  const yAxisId = options.yAxisId || "y-axis-0";
  const dataSets = chartInstance.data.datasets;
  const ctx = chartInstance.ctx;
  if (!dataSets || !ctx) {
    return;
  }

  const dataSetId = dataSets.findIndex((item) => item.yAxisID === yAxisId);
  const dataSet = dataSets[dataSetId];
  const datasetMeta = chartInstance.getDatasetMeta(dataSetId);
  const yScale = (chartInstance as any).scales[yAxisId] as {
    getPixelForValue: (x: number) => number;
  };

  const trendlineCalculator = {
    count: 0,
    sumY: 0,
    sumX: 0,
    sumX2: 0,
    sumXY: 0,
  };
  (dataSet.data as number[]).forEach((value, index) => {
    trendlineCalculator.count++;
    trendlineCalculator.sumX += index;
    trendlineCalculator.sumY += value;
    trendlineCalculator.sumX2 += index * index;
    trendlineCalculator.sumXY += index * value;
  });

  const lastIndex = datasetMeta.data.length - 1;
  const startPosition = datasetMeta.data[0]._model.x;
  const endPosition = datasetMeta.data[lastIndex]._model.x;
  const divisor =
    trendlineCalculator.count * trendlineCalculator.sumX2 -
    trendlineCalculator.sumX * trendlineCalculator.sumX;
  const offset =
    (trendlineCalculator.sumX2 * trendlineCalculator.sumY -
      trendlineCalculator.sumX * trendlineCalculator.sumXY) /
    divisor;
  const scale =
    (trendlineCalculator.count * trendlineCalculator.sumXY -
      trendlineCalculator.sumX * trendlineCalculator.sumY) /
    divisor;

  ctx.lineWidth = 2;
  ctx.beginPath();
  ctx.moveTo(startPosition, yScale.getPixelForValue(offset));
  ctx.lineTo(endPosition, yScale.getPixelForValue(offset + lastIndex * scale));
  ctx.strokeStyle = "rgba(127, 127, 127, .8)";
  ctx.setLineDash([5, 5]);
  ctx.stroke();
}

export default React.memo(ChartJs);
