export async function Retry<T>(callback: () => Promise<T> | T, errorMessage?: string, errorCallback?: (err: any) => any, maxRetries: number = 5): Promise<T> {
    let retry = 0;
    let error: any;
    while (retry < maxRetries) {
      try {
        retry++;
        return await callback();
      }
      catch (err: any) {
        try {
          error = errorCallback ? await errorCallback(err) : err;
        }
        catch {
          error = err;
        }
        console.warn(`[Retry ${retry}/${maxRetries}] ${errorMessage || "Retry failed"}:`);
        console.warn(error);
      }
    }
    throw error;
  }

export function SortByProperty<T>(items: T[], key: keyof T, descending: boolean = false): T[] {
    return items.slice().sort((a: T, b: T) => ((descending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
  }
