import { Configuration, PublicClientApplication, RedirectRequest } from "@azure/msal-browser";
import { TokenCredential } from "@azure/identity";
import { defaultCloud } from "./Environment";

const localAadConfig: StringMap<{ clientId: string; authority: string; tenantId: string; }> = {
    prod: {
        clientId: "bbefd4b6-1079-4122-b895-3fa9271cd994",
        authority: "https://login.microsoftonline.com/",
        tenantId: "72f988bf-86f1-41af-91ab-2d7cd011db47"
    },
    usnat: {
        clientId: "e47f86d2-7b64-46b9-ada7-83ba513b42d1",
        authority: "https://login.microsoftonline.eaglex.ic.gov/",
        tenantId: "70a90262-f46c-48aa-ac4c-37e37f8be1a2"
    },
    ussec: {
        clientId: "86277dc7-5992-481f-8840-b7b03f069b74",
        authority: "https://login.microsoftonline.microsoft.scloud/",
        tenantId: "20ac2fc4-effc-4f76-ba7d-63c5ae134f5f"
    }
};
const aadConfig = localAadConfig[defaultCloud];

const configuration: Configuration = {
    auth: {
        clientId: aadConfig.clientId,
        authority: `${aadConfig.authority}${aadConfig.tenantId}`
    }
};

export const msalInstance = new PublicClientApplication(configuration);

export const loginRequest: RedirectRequest = {
    scopes: ["openid", "profile"]
}

export async function acquireToken(scopes?: string | string[]) {
    scopes = scopes || "api://bbefd4b6-1079-4122-b895-3fa9271cd994/Analyzer.Read";
    const account = msalInstance.getActiveAccount() || msalInstance.getAllAccounts()[0];
    if (!account) {
        throw new Error("Can't acquire token before login.");
    }

    try {
        return await msalInstance.acquireTokenSilent({
            scopes: typeof scopes === "string" ? [scopes] : scopes,
            account
        });
    } catch (e) {
        await msalInstance.loginRedirect({
            scopes: ["openid", "profile"],
            redirectUri: window.location.origin,
            redirectStartPage: window.location.href
        });
        throw e;
    }
}

export const tokenCredential: TokenCredential = {
    getToken: async (scopes) => {
        const { accessToken, extExpiresOn } = await acquireToken(scopes);
        return {
            token: accessToken,
            expiresOnTimestamp: extExpiresOn!.getTime()
        }
    }
};
