import { Component, ErrorInfo, ReactNode } from "react";
import { SeverityLevel } from "@microsoft/applicationinsights-common";
import { reactPlugin } from "../AppInsights";

type Props = Readonly<{
    renderError: (error: Error, errorInfo: ErrorInfo) => ReactNode;
}>;

type State = {
    error?: Error;
    errorInfo?: ErrorInfo;
};

export class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);

        this.state = {
            error: undefined,
            errorInfo: undefined,
        };
    }

    componentDidCatch = (error: Error, errorInfo: ErrorInfo): void => {
        reactPlugin.trackException({
            error: error,
            exception: error,
            severityLevel: SeverityLevel.Error,
            properties: errorInfo
        });
        this.setState({ error, errorInfo });
    };

    render = (): ReactNode => {
        const { error, errorInfo } = this.state;

        return !!error && !!errorInfo ? this.props.renderError(error, errorInfo) : this.props.children;
    };
}
