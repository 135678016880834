import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate, useMsal } from '@azure/msal-react';
import 'purecss/build/pure.css';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
import { ajaxWithAuth } from '../Common/Ajax';
import { ErrorMessageBar } from "../Common/ErrorMessageBar";
import { Icons } from '../Common/Icons';
import './Layout.css';
import SideBar from './SideBar';
import SplashScreen from './SplashScreen';
import './SvgStyles.css';
import TopBar from './TopBar';

interface Props {
  title: string;
}

const Layout = (props: PropsWithChildren<Props>) => {
  const [auth, setAuth] = useState<{ success?: boolean; error?: any; }>();
  const { accounts } = useMsal();
  const account = accounts[0];
  useEffect(() => {
    let disposed = false;
    setAuth(undefined);
    if (account) {
      ajaxWithAuth("/api/checkAccess")
        .then(() => { !disposed && setAuth({ success: true }) })
        .catch(error => {
          if (!disposed) {
            setAuth({ error });
          }
        });
    }

    return () => { disposed = true; };
  }, [account, setAuth]);

  React.useEffect(() => {
    document.title = props.title
  }, [props.title]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={{
        scopes: ["openid", "profile"],
        redirectUri: window.location.origin,
        redirectStartPage: window.location.href
      }}
      errorComponent={ErrorMessageBar}
      loadingComponent={SplashScreen}>
      {!auth
        ? (<SplashScreen />)
        : (
          <Router>
            <>
              <div className="fxs-portal">
                <TopBar title={props.title} />
                {auth.success
                  ? (
                    <div className="fxs-body-container">
                      <SideBar />
                      <div className="fxs-body">
                        {props.children}
                      </div>
                    </div>
                  ) : (
                    <ErrorMessageBar error={auth.error} />
                  )}
              </div>
              {Icons.indicators}
            </>
          </Router>
        )}

    </MsalAuthenticationTemplate>
  );
};

export default React.memo(Layout);
