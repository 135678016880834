import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { instrumentationKey } from "./Common/Environment";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";

export const reactPlugin = new ReactPlugin();
export const appInsights = new ApplicationInsights({
    config: {
        instrumentationKey,
        extensions: [reactPlugin],
        enableAutoRouteTracking: true,
        disableFetchTracking: false
    },
});
appInsights.loadAppInsights();