import React from 'react';

export interface HashInfo {
  hash: string;
  path: string;
  query: StringMap<string>;
}

export function useHashInfo() {
  const [hashInfo, setHashInfo] = React.useState<HashInfo>(() => parseHash(window.location.hash));
  React.useEffect(() => {
    const handleHash = () => {
      const newHash = window.location.hash;
      if (hashInfo?.hash !== newHash) {
        setHashInfo(parseHash(newHash));
      }
    };
    window.addEventListener("hashchange", handleHash, false);
    return () => {
      window.removeEventListener("hashchange", handleHash, false);
    };
  }, [hashInfo, setHashInfo]);
  return hashInfo;
}

export function setHashInfo(hashInfo: Partial<HashInfo>) {
  window.location.hash = formatHash({
    ...parseHash(window.location.hash),
    ...hashInfo
  });
}

function parseHash(hash: string): HashInfo {
  const indexOfQ = hash.indexOf("?");
  let path = indexOfQ === -1 ? hash : hash.substr(0, indexOfQ);
  path = path[0] === "#" ? path.substr(1) : path;
  const query: StringMap<string> = indexOfQ >= 0
    ? parseQuery(hash.substr(indexOfQ))
    : {};

  return {
    hash,
    path,
    query,
  };
}

function parseQuery(input: string) {
  const query: StringMap<string> = {};
  if (input[0] === "?") {
    input = input.substr(1);
  }

  if (input) {
    const params = input.split("&");
    params.forEach(param => {
      const indexOfEquals = param.indexOf("=");
      if (indexOfEquals >= 0) {
        const key = decodeURIComponent(param.substr(0, indexOfEquals).toLowerCase());
        query[key] = decodeURIComponent(param.substr(indexOfEquals + 1));
      }
    });
  }

  return query;
}

function formatHash(info: HashInfo): string {
  let result = info.path;
  const query = formatQuery(info.query);

  if (query) {
    result += "?" + query;
  }

  return result;
}

function formatQuery(query: StringMap<string>) {
  query = query || {};
  let result = Object.keys(query).map(key => {
    return encodeURIComponent(key) + "=" + encodeURIComponent(query[key]);
  }).join("&");
  return result;
}
