import React from 'react';

const NotFound = () => {
  const url = new URL(window.location.href);
  const canRedirect = url.searchParams.get("redirected") !== "true";
  React.useEffect(() => {
    if (canRedirect) {
      url.searchParams.set("redirected", "true")
      if (url.port === "3000") {
        url.port = "4000";
      }

      window.setTimeout(() => {
        window.location.replace(url.toString());
      }, process.env.NODE_ENV === "development" ? 2000 : 0)
    }
  });
  return (<>{canRedirect ? "Redirecting..." : "Not found"}</>);
};

export default React.memo(NotFound);
