export type EnvironmentName = "mc" | "ff" | "usnat" | "ussec" | "prod";

const Analyzer: {
    defaultCloud: EnvironmentName;
    instrumentationKey: string;
} = (window as any).Analyzer || {
    defaultCloud: "prod",
    instrumentationKey: "acdb6d9a-2199-4f3b-8adc-0dd1766afbcd"
};

export const defaultCloud = Analyzer.defaultCloud;
export const instrumentationKey = Analyzer.instrumentationKey;
export const cloud = sessionStorage.getItem("analyzer.cloud") as EnvironmentName || defaultCloud;

export const cloudDisplayNames: { [key in EnvironmentName]: string; } = {
    prod: "Public Azure (portal.azure.com)",
    ff: "Fairfax (portal.azure.us)",
    mc: "Mooncake (portal.azure.cn)",
    usnat: "USNat (portal.azure.eaglex.ic.gov)",
    ussec: "USSec (portal.azure.microsoft.scloud)",
};
export const cloudDisplayName = cloudDisplayNames[cloud];
