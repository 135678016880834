import { ajax } from './Ajax';
import { getBlobText } from './StorageHelpers';

export const portalAuthorityMap: StringMap<string> = {
    "prod": "portal.azure.com",
    "ff": "portal.azure.us",
    "mc": "portal.azure.cn",
    "usnat": "portal.azure.eaglex.ic.gov",
    "ussec": "portal.azure.microsoft.scloud",
};

const classicIaasExtensionNames: StringMap<boolean> = {
    "microsoft_azure_classic_compute": true,
    "microsoft_azure_classic_network": true,
    "microsoftazureclassicstorageextension": true,
};

export interface BuildVersion {
    readonly major: number;
    readonly minor: number;
    readonly build: number;
    readonly revision: number;
    includes(version?: BuildVersion): boolean;
}

export function parseVersion(version: string): BuildVersion | undefined {
    const parts = version.split(".");

    if (parts.length !== 4) {
        return undefined;
    }

    const major = parseInt(parts[0]);
    const minor = parseInt(parts[1]);
    const build = parseInt(parts[2]);
    const revision = parseInt(parts[3]);
    return {
        major,
        minor,
        build,
        revision,
        includes: comparer => {
            if (!comparer) {
                return false;
            }

            const compareArrays = (ver1: number[], ver2: number[], pos: number): boolean => {
                const num1 = ver1[pos];
                const num2 = ver2[pos];
                if (num1 > num2) {
                    return true;
                } else if (num1 === num2) {
                    if (pos === ver1.length - 1) {
                        return ver1.length === ver2.length;
                    } else if (pos === ver2.length - 1) {
                        return true;
                    }

                    return compareArrays(ver1, ver2, pos + 1);
                } else {
                    return false;
                }
            }

            return compareArrays(
                [major, minor, build, revision],
                [comparer.major, comparer.minor, comparer.build, comparer.revision],
                0);
        },
    };
}

export function getDeploymentStageLabel(stage: string, regions: string) {
    return regions ? `${stage} (${regions})` : stage;
}

export function getFriendlyVersionName(version: string, extensionName?: string): string {
    version = version || "";
    extensionName = extensionName || "";

    const extensionNameLowerCase = extensionName.toLowerCase();

    if (classicIaasExtensionNames[extensionNameLowerCase]) {
        if (version.indexOf("5.12.") === 0) {
            return version.substr(0, 10);
        }

        if (version.indexOf("5.13.0.") === 0) {
            return version.substr(0, 10);
        }

        if (version.indexOf("5.14.0.") === 0) {
            return version.substr(0, 9);
        }
    }

    const indexOfParan = version.indexOf("(");
    if (indexOfParan > 0) {
        return version.substr(0, indexOfParan).trim();
    }

    return version;
}

export function searchCommits(repositoryName: string, queryText: string, skip?: number): Promise<GetCommitsResponse> {
    const options: GetCommitsOptions = {
        repo: repositoryName,
        needle: queryText,
        skip
    };
    return ajax("/api/getCommits", {
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => res.json());
}

export function getBuildBranch(repositoryName: string, builds: string[]): Promise<GetBuildBranchResponse> {
    const options: GetBuildBranchOptions = {
        repo: repositoryName,
        builds
    };
    return ajax("/api/getBuildBranch", {
        method: "POST",
        body: JSON.stringify(options),
        headers: {
            "Content-Type": "application/json",
        }
    }).then(res => res.json());
}

export type DeploymentHistory = StringMap<DeploymentHistoryItem[]>;

export interface DeploymentHistoryItem {
    timestamp: string;
    build: string;
    rollout: string;
    releaseUrl: string;
}

export async function getDeploymentHistory(extensionName: string, containerName: string = "deployments"): Promise<DeploymentHistory> {
    extensionName = extensionName.toLowerCase();
    const history = await getBlobText("commits", containerName, `${extensionName}.json`)
        .then(async text => {
            const content: DeploymentHistory = JSON.parse(text);
            return content;
        }).catch((res: Response): DeploymentHistory => {
            if (res?.status === 404) {
                return {};
            }

            throw res;
        });

    return history;
}
