import LRU from "lru-cache";
import { openSwitcher } from "../Layout/TopBar";
import { acquireToken } from "./Auth";
import { cloud, defaultCloud } from "./Environment";
import { Retry } from "./Util";

const cloudDstsToken = cloud && sessionStorage.getItem(`analyzer.${cloud}-dsts`);
const ADO_GUID = "499b84ac-1321-427f-aa17-267ca6975798";

let dstsExpiryPromptTime: number;

const ajaxCache = new LRU<string, Promise<Response>>({ max: 200, maxAge: 1 * 60 * 1000 }); // 1 minute cache entry time limit

interface AjaxOptions {
    settings?: RequestInit;
    useDsts?: boolean;
    scope?: string;
    noCache?: boolean;
    maxAge?: number; // maximum time (in ms) that a cache entry can persist - will override the cache limit for that specific entry
}

export async function ajaxWithAuth<T>(url: string, options?: AjaxOptions, processResponse: boolean = true): Promise<T> {
    let { settings, useDsts, scope, noCache, maxAge } = options || {};
    useDsts = useDsts && cloud !== defaultCloud;
    const token = useDsts
        ? cloudDstsToken
        : `Bearer ${(await acquireToken(scope)).accessToken}`
    if (!token) {
        throw new Error("Token not acquired during authentication.");
    }

    settings = { method: "POST", ...settings };
    settings.headers = {
        "Authorization": token,
        "Content-Type": "application/json",
        "x-ms-app": "ExtensionAnalyzer",
        ...settings.headers
    }
    const res = await ajax(url, settings, noCache, maxAge);
    if (res.ok) {
        if (processResponse) {
            const text = await res.text();
            return text ? JSON.parse(text) : undefined;
        }

        return res as any;
    }

    if (useDsts && res.status === 401 && !(dstsExpiryPromptTime + 10000 > new Date().getTime())) {
        dstsExpiryPromptTime = new Date().getTime();
        if (window.confirm("Kusto access denied. Would you like to try generating a new dSTS token?")) {
            openSwitcher();
        }
    }

    throw res;
}

export async function ajax(url: string, init?: RequestInit, noCache?: boolean, maxAge?: number): Promise<Response> {
    const key = `${init?.method || "GET"}/${url}/${JSON.stringify(init?.body)}`;
    let promise = noCache ? null : ajaxCache.get(key);
    if (!promise) {
        if (url.startsWith("/api/")) {
            init = init || {};
            const headers = init.headers = new Headers(init.headers);
            headers.set("Authorization", `Bearer ${(await acquireToken()).accessToken}`);
        }

        promise = fetch(url, init);
        ajaxCache.set(key, promise, maxAge);
    }

    const res = await promise;
    if (!res.ok) {
        ajaxCache.del(key);
    }

    return res.clone();
}

// Impersonates a user and makes a request to given api
export async function devOpsAjax<T>(api: string, settings?: RequestInit, processResponse: boolean = true, noCache?: boolean): Promise<T> {
    settings = { method: "GET", ...settings };
    const ajaxCallback = async () => await ajaxWithAuth<T>(api, { settings: settings, scope: `${ADO_GUID}/user_impersonation`, noCache }, processResponse);
    const errorMessage = "An error occured while making a request";
    const errorCallback = async (err: any) => { const error = await err.json(); return new Error(error.message ?? error) };
    return await Retry(ajaxCallback, errorMessage, errorCallback);
}
