import React from 'react';
import { Icons, getIndicatorIcon, IndicatorMode } from '../Common/Icons';
import { cloud } from '../Common/Environment';
import { ajaxWithAuth } from '../Common/Ajax';
import Banner, { BannerValue } from '../Components/Banner';
import { cloudKusto } from '../Common/Kusto';
import KustoLinks from '../Components/KustoLinks';
import Grid, { Column, Align } from '../Components/Grid';
import { useParams } from 'react-router-dom';

interface Params {
  productName: string;
}

const ProductParts = () => {
  const { productName } = useParams<Params>();
  const [banner, setBanner] = React.useState<BannerValue>();
  const [queryText, setQueryText] = React.useState("");
  const [showHighUsageOnly, setShowHighUsageOnly] = React.useState(cloud === "prod");
  const [showAboveThresholdOnly, setShowAboveThresholdOnly] = React.useState(false);
  const [response, setResponse] = React.useState<ProductPartsResponse>();
  React.useEffect(() => {
    setBanner({ text: resources.loadingLabel, svg: Icons.loading });
    ajaxWithAuth<ProductPartsResponse>(`/api/products/${productName}/parts?env=${cloud}`, { useDsts: true }).then(res => {
      setResponse(res)
      setBanner(undefined);
    }).catch(error => {
      setBanner({ svg: Icons.failure, error });
    });
  }, [productName]);
  const dataFreshness = response && resources.dataFreshnessFormat.replace("{0}", response.endTime);
  return (
    <>
      <Banner value={banner} />
      <div className="fxs-tab-section fxs-wrapitems">
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-parts-environment">{resources.environmentLabel}</div>
          <select className="fxs-tab-section-field-value" aria-labelledby="product-parts-environment" disabled>
            <option value="prod">{cloudKusto.displayName}</option>
          </select>
        </div>
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-parts-search">{resources.queryTextLabel}</div>
          <input className="product-parts-search" aria-labelledby="product-parts-search" type="search" value={queryText} onChange={e => setQueryText(e.target.value)} />
        </div>
        <div className="fxs-tab-section-field">
          <input type="checkbox" aria-labelledby="product-parts-highusageonly" checked={showHighUsageOnly} onChange={e => setShowHighUsageOnly(e.target.checked)} />
          <div id="product-parts-highusageonly">{resources.showHighUsageOnly}</div>
        </div>
        <div className="fxs-tab-section-field">
          <input type="checkbox" aria-labelledby="product-parts-abovethresholdonly" checked={showAboveThresholdOnly} onChange={e => setShowAboveThresholdOnly(e.target.checked)} />
          <div id="product-parts-abovethresholdonly">{resources.showAboveThresholdOnlyOnly}</div>
        </div>
      </div>
      <section className="fxs-tab-section">
        <h2 style={{ margin: 0 }} >{resources.sectionHeader}</h2>
        <div style={{ margin: "8px 0" }}>{dataFreshness}</div>
        <KustoLinks server={response?.kustoServerName} database={response?.kustoDatabaseName} query={response?.kustoQueryText} />
        <Grid
          columns={columns}
          rows={response?.parts}
          altRows={true}
          queryText={queryText}
          pageSize={20}
          noRowsMessage={response && resources.noPartLoadsData} />
      </section>
    </>
  );
};

const resources = {
  loadingLabel: "Downloading part load information",
  sectionHeader: "Part usage",
  showHighUsageOnly: "Show high usage parts only (+5k loads per week)",
  showAboveThresholdOnlyOnly: "Show above threshold only (P95 >= 8s)",
  noPartLoadsData: "No parts were loaded during the specified period",
  gridHeaders: {
    extensionName: "Extension",
    partName: "Part",
    partLoadCount: "Load count",
    duration50: "Load P50",
    duration80: "Load P80",
    duration95: "Load P95",
    duration99: "Load P99",
    uniqueUserCount: "Total unique users",
  },
  dataFreshnessFormat: `Usage for week ending {0}`,
  environmentLabel: "Environment",
  queryTextLabel: "Filter"
};

const columns: Column<ProductPartsResponseItem>[] = [
  {
    headerText: resources.gridHeaders.extensionName,
    key: row => (<a href={`/extensions/${row.extensionName}#parts`}>{row.extensionName}</a>),
    sortKey: row => row.extensionName
  },
  {
    headerText: resources.gridHeaders.partName,
    key: row => row.partName,
  },
  {
    headerText: resources.gridHeaders.partLoadCount,
    key: row => (<>{getIndicatorIcon(row.loadCountPrevious, row.loadCount, IndicatorMode.Neutral)}{row.loadCount}</>),
    sortKey: row => row.loadCount,
    align: Align.Right,
  },
  {
    headerText: resources.gridHeaders.uniqueUserCount,
    key: row => (<>{getIndicatorIcon(row.uniqueUserCountPrevious, row.uniqueUserCount, IndicatorMode.Neutral)}{row.uniqueUserCount}</>),
    sortKey: row => row.uniqueUserCount,
    align: Align.Right,
  },
  {
    headerText: resources.gridHeaders.duration50,
    key: row => (<>{getIndicatorIcon(row.duration50Previous, row.duration50, IndicatorMode.Inverse)}{row.duration50}</>),
    sortKey: row => row.duration50,
    getCss: row => row.duration50 < 2000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
    align: Align.Right,
  },
  {
    headerText: resources.gridHeaders.duration80,
    key: row => (<>{getIndicatorIcon(row.duration80Previous, row.duration80, IndicatorMode.Inverse)}{row.duration80}</>),
    sortKey: row => row.duration80,
    getCss: row => row.duration80 < 4000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
    align: Align.Right,
  },
  {
    headerText: resources.gridHeaders.duration95,
    key: row => (<>{getIndicatorIcon(row.duration95Previous, row.duration95, IndicatorMode.Inverse)}{row.duration95}</>),
    sortKey: row => row.duration95,
    getCss: row => row.duration95 < 8000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
    align: Align.Right,
  },
  {
    headerText: resources.gridHeaders.duration99,
    key: row => (<>{getIndicatorIcon(row.duration99Previous, row.duration99, IndicatorMode.Inverse)}{row.duration99}</>),
    sortKey: row => row.duration99,
    getCss: row => row.duration99 < 16000 ? "fxs-controls-grid-cell-success" : "fxs-controls-grid-cell-failure",
    align: Align.Right,
  },
];

export default React.memo(ProductParts);
