import React from 'react';
import Tabs, { TabOptions } from '../Components/Tabs';
import './Product.css';
import { RouteComponentProps, useParams } from 'react-router-dom';
import ProductsOverview from './ProductOverview';
import ProductsResources from './ProductResources';
import { cloud } from '../Common/Environment';
import ProductCreateFlows from './ProductCreateFlows';
import ProductBlades from './ProductBlades';
import ProductParts from './ProductParts';
import ProductClientErrors from './ProductClientErrors';

interface Props extends RouteComponentProps {
  setTitle: (title: string) => void;
}

interface Params {
  productName: string;
}

const Product = (props: Props) => {
  const { productName } = useParams<Params>();
  const { setTitle, ...restProps } = props;
  React.useEffect(() => {
    setTitle(productName);
  }, [productName, setTitle]);

  const tabs: TabOptions[] = [
    {
      id: "overview",
      title: resources.tabTitles.overview,
      content: <ProductsOverview />
    },
    {
      id: "resources",
      title: resources.tabTitles.resources,
      content: <ProductsResources />
    },
    {
      id: "create",
      title: resources.tabTitles.create,
      content: <ProductCreateFlows />,
      hidden: cloud !== "prod"
    },
    {
      id: "blades",
      title: resources.tabTitles.blades,
      content: <ProductBlades />
    },
    {
      id: "parts",
      title: resources.tabTitles.parts,
      content: <ProductParts />
    },
    {
      id: "clienterrors",
      title: resources.tabTitles.clienterrors,
      content: <ProductClientErrors />
    }
  ];
  return (
    <Tabs {...restProps} tabs={tabs} />
  );
};

const resources = {
  tabTitles: {
    overview: "Overview",
    resources: "Resources usage",
    create: "Create flows",
    blades: "Blades",
    parts: "Parts",
    clienterrors: "Client errors"
  }
};

export default React.memo(Product);
