import React from 'react';
import { Icons } from '../Common/Icons';
import { cloud } from '../Common/Environment';
import { ajaxWithAuth } from '../Common/Ajax';
import Banner, { BannerValue } from '../Components/Banner';
import { cloudKusto } from '../Common/Kusto';
import KustoLinks from '../Components/KustoLinks';
import Grid, { Column, Align } from '../Components/Grid';
import { useParams } from 'react-router-dom';

interface Params {
  productName: string;
}

const ProductResources = () => {
  const { productName } = useParams<Params>();
  const [banner, setBanner] = React.useState<BannerValue>();
  const [queryText, setQueryText] = React.useState("");
  const [response, setResponse] = React.useState<ProductResourcesResponse>();
  React.useEffect(() => {
    setBanner({ text: resources.loadingLabel, svg: Icons.loading });

    ajaxWithAuth<ProductResourcesResponse>(`/api/products/${productName}/resources?env=${cloud}`, { useDsts: true }).then(res => {
      setResponse(res)
      setBanner(undefined);
    }).catch(error => {
      setBanner({ svg: Icons.failure, error });
    });
  }, [productName]);
  const dataFreshness = response && resources.dataFreshnessFormat.replace("{0}", response.endTime);
  return (
    <>
      <Banner value={banner} />
      <div className="fxs-tab-section fxs-wrapitems">
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-resources-environment">{resources.environmentLabel}</div>
          <select className="fxs-tab-section-field-value" aria-labelledby="product-resources-environment" disabled>
            <option value="prod">{cloudKusto.displayName}</option>
          </select>
        </div>
        <div className="fxs-tab-section-field">
          <div className="fxs-tab-section-field-label" id="product-resources-search">{resources.queryTextLabel}</div>
          <input className="product-resources-search" aria-labelledby="product-resources-search" type="search" value={queryText} onChange={e => setQueryText(e.target.value)} />
        </div>
      </div>
      <section className="fxs-tab-section">
        <h2 style={{ margin: 0 }} >{resources.resourcesSectionHeader}</h2>
        <div style={{ margin: "8px 0" }}>{dataFreshness}</div>
        <KustoLinks server={response?.kustoServerName} database={response?.kustoDatabaseName} query={response?.kustoQueryText} />
        <Grid
          columns={columns}
          rows={response?.resources}
          altRows={true}
          queryText={queryText}
          pageSize={20}
          noRowsMessage={response && resources.noBladeLoadsData} />
      </section>
    </>
  );
};

const resources = {
  environmentLabel: "Environment",
  queryTextLabel: "Filter",
  loadingLabel: "Downloading resource usage information",
  resourcesSectionHeader: "How often are resources managed?",
  noBladeLoadsData: "No resources were managed during the specified period",
  gridHeaders: {
    extensionName: "Extension",
    providerName: "Provider",
    bladeLoadCount: "Load count",
    uniqueUserCount: "Total unique users",
  },
  dataFreshnessFormat: `Usage for week ending {0}`,
};

const columns: Column<ProductResourcesResponseItem>[] = [
  {
    headerText: resources.gridHeaders.extensionName,
    key: row => (<a href={`/extensions/${row.extensionName}#resources`}>{row.extensionName}</a>),
    sortKey: row => row.extensionName
  },
  {
    headerText: resources.gridHeaders.providerName,
    key: row => row.providerName,
  },
  {
    headerText: resources.gridHeaders.bladeLoadCount,
    key: row => row.bladeLoadCount,
    align: Align.Right,
  },
  {
    headerText: resources.gridHeaders.uniqueUserCount,
    key: row => row.uniqueUserCount,
    align: Align.Right,
  },
];


export default React.memo(ProductResources);
