import React from 'react';
import './Grid.css';

export interface Props<T> {
  columns: Column<T>[];
  altRows?: boolean;
  hideHeaderRow?: boolean;
  queryText?: string;
  rows?: T[];
  noRowsMessage?: string;
  pageSize?: number;
  customHeader?: React.ReactNode;
  verticalAlign?: VerticalAlign;
}

export interface Column<T> {
  headerText: string;
  key: string | ((row: T) => any);
  sortKey?: string | ((row: T) => any);
  visible?: boolean;
  wrapped?: boolean;
  align?: Align;
  verticalAlign?: VerticalAlign;
  getCss?: (item: T) => string;
  sort?: Sort;
}

export enum Align {
  Left = 1,
  Center = 2,
  Right = 3,
  Justify = 4,
}

export enum VerticalAlign {
  Top = 1,
  Center = 2,
  Bottom = 3
}

export enum Sort {
  Asc = 1,
  Desc = 2
}

const Grid = <T extends StringMap<any>>(props: React.PropsWithChildren<Props<T>>) => {
  const { columns, altRows, hideHeaderRow, queryText, noRowsMessage, customHeader } = props;
  const pageSize = props.pageSize || 1000;
  const rows = props.rows || [];

  const [currentPage, setCurrentPage] = React.useState(1);
  const [sortState, setSortState] = React.useState<SortState<T>>({
    sortAsc: true
  });

  const rowCount = rows.length;
  const pageCount = Math.ceil(rowCount / pageSize);
  const pages = Array.from(Array(pageCount).keys()).map(i => i + 1);

  const mappedColumns = columns.map(column => {
    const cssClasses = [column.wrapped ? "fxs-controls-grid-cell-wrapped" : "fxs-controls-grid-cell"];
    switch (column.align) {
      case Align.Center:
        cssClasses.push("aligncenter");
        break;
      case Align.Right:
        cssClasses.push("alignright");
        break;
      case Align.Justify:
        cssClasses.push("alignjustify");
        break;
      default:
        break;
    }

    switch (column.verticalAlign || props.verticalAlign) {
      case VerticalAlign.Top:
        cssClasses.push("aligntop");
        break;
      case VerticalAlign.Bottom:
        cssClasses.push("alignbottom");
        break;
      default:
        break;
    }

    const getValue = (item: T): any => {
      return typeof column.key === "string" ? item[column.key] : column.key(item);
    };
    const getSortValue = (item: T): any => {
      let sort = column.sortKey || column.key;
      return typeof sort === "string" ? item[sort] : sort(item);
    };
    const getSortStateByColumn = (ret: Column<T>): SortState<T> => {
      const currentCol = sortState.sortColumn;
      if (currentCol === ret) {
        return { ...sortState, sortAsc: !sortState.sortAsc };
      } else {
        return {
          getSortKey: row => {
            let rowData = getSortValue(row.item);
            return rowData;
          },
          sortColumn: ret,
          sortAsc: true
        };
      }
    };
    const ret: InternalColumn<T> = {
      headerText: column.headerText,
      getText: (item: T) => {
        const value = getValue(item);
        return value instanceof Date ? value.toLocaleString() : value;
      },
      getSortValue,
      visible: column.visible || column.visible !== false,
      wrapped: column.wrapped,
      headerCss: (() => {
        let result = "fxs-controls-grid-cell fxs-controls-grid-columnheader ";
        if (column === sortState.sortColumn) {
          if (sortState.sortAsc) {
            result += "fxs-controls-grid-columnheader-background-sortasc";
          } else {
            result += "fxs-controls-grid-columnheader-background-sortdesc";
          }
        } else {
          result += "fxs-controls-grid-columnheader-background-unsorted";
        }

        return result;
      })(),
      getCss: item => {
        const customCss = column.getCss && column.getCss(item);
        return cssClasses.join(" ") + (customCss ? " " + customCss : "");
      },
      onClick: () => setSortState(getSortStateByColumn(column)),
    };
    if (column.sort) {
      const desiredSortState = {
        ...getSortStateByColumn(column),
        sortAsc: column.sort === Sort.Asc
      };
      if (sortState.sortColumn !== desiredSortState.sortColumn && sortState.sortAsc !== desiredSortState.sortAsc) {
        setSortState(desiredSortState);
      }
    }

    return ret;
  });

  const mappedRows = rows.map(item => {
    const keywordItems = mappedColumns.map(column => column.getSortValue(item));
    const row: InternalRow<T> = {
      item: item,
      keywords: keywordItems.join(" "),
      getCss: (index: number) => {
        return index % 2 !== 0 ? "even" : "odd";
      },
    };
    return row;
  });
  const filteredRows = mappedRows.filter(row => {
    const keywords = (row.keywords || "").toLowerCase();
    return keywords.indexOf((queryText || "").toLowerCase()) >= 0;
  });
  const sortedRows = sortArray(filteredRows, sortState.sortAsc, sortState.getSortKey);

  const selectedPageIndex = currentPage - 1;
  const pagedRows = pageArray<InternalRow<T>>(sortedRows, pageSize, selectedPageIndex);
  const goToPrevPage = () => {
    setCurrentPage(currentPage - 1);
  };
  const goToNextPage = () => {
    setCurrentPage(currentPage + 1);
  };
  let tableCss = "fxs-controls-grid";
  if (altRows) {
    tableCss += " fxs-controls-grid-altrows"
  }

  return (
    <div>
      {(!!customHeader || pages.length > 1) && <div className="fxs-controls-grid-header">
        <div className="fxs-controls-grid-header-custom">{customHeader}</div>
        {pages.length > 1 && (<div className="fxs-controls-grid-header-paging">
          <button className="fxs-button-link" style={{ paddingRight: "4px" }} onClick={goToPrevPage} disabled={currentPage <= 1}>{resources.previousPage}</button>
          <select aria-label={resources.gridPageSelector} className="fxs-tab-section-field-value" value={currentPage} onChange={e => setCurrentPage(+e.target.value)}>
            {pages.map(item => <option value={item} key={item}>{item}</option>)}
          </select>
          <button className="fxs-button-link" style={{ paddingLeft: "4px" }} onClick={goToNextPage} disabled={currentPage >= pages.length}>{resources.nextPage}</button>
        </div>)}
      </div>}
      {pagedRows.length
        ? (<table className={tableCss}>
          <thead style={{ display: hideHeaderRow ? "none" : undefined }}>
            <tr>
              {mappedColumns.filter(item => item.visible).map((item, index) => {
                return (<td key={index} className={item.headerCss} onClick={item.onClick}>{item.headerText}</td>)
              })}
            </tr>
          </thead>
          <tbody>
            {pagedRows.map((row, rowIndex) => {
              return (
                <tr className={row.getCss(rowIndex)} key={rowIndex}>
                  {mappedColumns.filter(item => item.visible).map((column, columnIndex) => {
                    const columnCss = column.getCss(row.item);
                    const content = column.getText(row.item);
                    return (
                      <td className={columnCss} key={columnIndex}>
                        {column.wrapped ? (<div>{content}</div>) : content}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>)
        : (<div className="fxs-controls-grid-norows">{noRowsMessage}</div>)}
    </div >
  );
};

function sortArray<T>(inputArray: T[], sortAsc: boolean, getSortKey?: (row: T) => any): T[] {
  const outputArray = inputArray.slice();
  if (getSortKey) {
    outputArray.sort((a, b) => {
      const keyA = getSortKey(a);
      const keyB = getSortKey(b);
      if (keyA === keyB) {
        return 0;
      } else if (sortAsc) {
        return keyA < keyB ? -1 : 1;
      }

      return keyA > keyB ? -1 : 1;
    });
  }

  return outputArray;
}

function pageArray<T>(array: T[], pageSize: number, pageIndex: number): T[] {
  return array.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
}

const resources = {
  gridPageSelector: "Grid page to be displayed",
  previousPage: "Previous page",
  nextPage: "Next page"
};

interface InternalColumn<T> {
  headerText: string;
  getText: (item: T) => any;
  getSortValue: (item: T) => any;
  visible: boolean;
  wrapped?: boolean;
  headerCss: string;
  getCss: (item: T) => string;
  onClick: () => void;
}

interface InternalRow<T> {
  item: T;
  keywords: string;
  getCss: (index: number) => string;
}

interface SortState<T> {
  getSortKey?: (row: InternalRow<T>) => any,
  sortColumn?: Column<T>,
  sortAsc: boolean
}

const typedMemo: <T>(c: T) => T = React.memo;
export default typedMemo(Grid);
