import React, { ErrorInfo } from "react";
import "./ErrorModule.css";

type Props = Readonly<{
    error: Error;
    errorInfo: ErrorInfo;
}>;
export class ErrorModule extends React.PureComponent<Props> {
    render() {
        return (
            <div className="error-summary">
                <div className="fxs-topbar ">
                    <p className="fxs-topbar-title">Extension Analyzer Error Page</p>
                </div>
                <div className="card-header">
                    <p>
                        OOPS!!! There was an error in loading this page. Please try to reload the page to make this
                        error go away. Thanks for using Extension Analyzer.
                    </p>
                </div>
                <div className="error-details">
                    {this.props.errorInfo && this.props.errorInfo.componentStack.toString()}
                </div>
            </div>
        );
    }
}
